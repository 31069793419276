import React from 'react';
import classNames from 'classnames';
import SkeletonItem from '@common/components/common/SkeletonItem';
import style from './style.module.scss';
const HeroSkeleton = ({ image }) => {
    return (<div id={'js-btn-container'}>
      <div className={style.container}>
        <div className={style.content}>
          <div className={style.titleContainer}>
            <SkeletonItem className={classNames(style.skeletonTitle, style.skeletonTitle_1, style.skeletonItem)}/>
            <SkeletonItem className={classNames(style.skeletonTitle, style.skeletonTitle_2, style.skeletonItem)}/>
            <SkeletonItem className={classNames(style.skeletonTitle, style.skeletonTitle_3, style.skeletonItem)}/>
          </div>
          <div className={style.subtitleContainer}>
            <SkeletonItem className={classNames(style.skeletonSubtitle, style.skeletonSubtitle_1, style.skeletonItem)}/>
            <SkeletonItem className={classNames(style.skeletonSubtitle, style.skeletonSubtitle_2, style.skeletonItem)}/>
          </div>
        </div>
        <div className={style.imageContainer}>{image}</div>
      </div>
      <SkeletonItem className={classNames(style.skeletonButton, style.skeletonItem)}/>
      <div className={style.descriptionContainer}>
        <SkeletonItem className={classNames(style.skeletonDescription, style.skeletonDescription_1, style.skeletonItem)}/>
        <SkeletonItem className={classNames(style.skeletonDescription, style.skeletonDescription_2, style.skeletonItem)}/>
      </div>
    </div>);
};
export default HeroSkeleton;
