import { createGate } from 'effector-react';
import { attach, createEffect, sample } from 'effector';
import { callAnalyticVisit } from '@analytics';
import { OffersModel } from '@website/template/main-template/lib/offers-model';
import { OFFERS_KEY_WORD_MAIN_WEBSITE } from '@website/shared/constants/website-keywords';
import { AuthStateModel } from '@common/entities/auth-state';
const MainGate = createGate({});
const sendAnalyticFx = createEffect(() => {
    callAnalyticVisit();
});
const attachedGetOffersFx = attach({
    effect: OffersModel.getOffersFx,
    mapParams: () => {
        return { keywords: [OFFERS_KEY_WORD_MAIN_WEBSITE] };
    },
});
sample({ clock: MainGate.open, target: sendAnalyticFx });
sample({
    clock: [MainGate.open, AuthStateModel.$authState],
    source: { authState: AuthStateModel.$authState, isOpen: MainGate.status },
    filter: ({ authState, isOpen }) => (authState === 'authorized' || authState === 'unauthorized') && isOpen,
    target: attachedGetOffersFx,
});
export const MainTemplateModel = {
    MainGate,
};
