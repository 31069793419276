import { useEffect, useState } from 'react';
import { useGate, useUnit } from 'effector-react';
import Image from 'next/image';
import { META_TAGS } from '@website/template/main-template/lib/meta-tags';
import { MainLayout } from '@website/widgets/layouts';
import { ApplicationBlock, FaqBlock, FeaturesBlock } from '@website/widgets/landings/main';
import { ShowcaseOffersWrapper } from '@website/widgets/showcase-offers-wrapper';
import { AppLinksBlock } from '@website/widgets/landings/app-links-block';
import HeroUniversal from '@website/widgets/landings/hero/hero-universal/hero-universal';
import showSkeleton from '@website/widgets/landings/hero/hero-universal/helpers/showSkeleton';
import { MainTemplateModel } from '@website/template/main-template/models';
import { OffersModel } from '@website/template/main-template/lib/offers-model';
import { SubscriptionsModel } from '@common/entities/subscriptions';
import { faq } from './lib/faq';
import ImgHero from './images/img_hero.webp';
import style from './style.module.scss';
import { applyOfferWebsiteFactory } from '@website/features/apply-offer/lib';
import { messages } from './messages';
import { useIntl } from 'react-intl';
const MainTemplate = () => {
    useGate(MainTemplateModel.MainGate);
    const intl = useIntl();
    const DEFAULT_TITLE = intl.formatMessage(messages.hero_title_default, { br: `<br />` });
    const offersState = useUnit(OffersModel.$offersState);
    const offerHero = useUnit(OffersModel.$offerHero);
    const showcaseOffers = useUnit(OffersModel.$showcaseOffers);
    const [title, setTitle] = useState('');
    const hasActiveSubscriptionStatus = useUnit(SubscriptionsModel.$hasActiveSubscriptionStatus);
    const hero = offerHero?.widgets.hero;
    const description = hero?.description ?? '';
    const subtitle = hero?.subtitle ?? '';
    const applyOffer = applyOfferWebsiteFactory();
    useEffect(() => {
        if (hero) {
            setTitle(hero?.title ?? DEFAULT_TITLE);
        }
    }, [hero]);
    return (<MainLayout title={intl.formatMessage(messages.layout_title)} metaTags={META_TAGS}>
      <HeroUniversal offer={offerHero} offersState={offersState} title={title} subtitle={subtitle} description={description} hasActiveSubscriptionStatus={hasActiveSubscriptionStatus} showSkeleton={showSkeleton({ offersState })} image={<Image className={style.image_hero} alt={intl.formatMessage(messages.hero_image_alt)} src={ImgHero}/>}/>
      <FeaturesBlock />
      {showcaseOffers.length > 0 && (<ShowcaseOffersWrapper offersState={offersState} offers={showcaseOffers} applyOffer={applyOffer}/>)}
      <ApplicationBlock />
      <FaqBlock content={faq}/>
      <AppLinksBlock />
    </MainLayout>);
};
export default MainTemplate;
