import { messages } from './messages';
import { intl } from '@common/shared/lib/intl';
export const faq = [
    {
        id: 1,
        sort: 1,
        title: intl.formatMessage(messages.titleQ1),
        answer: intl.formatMessage(messages.descriptionQ1),
    },
    {
        id: 2,
        sort: 2,
        title: intl.formatMessage(messages.titleQ2),
        answer: intl.formatMessage(messages.descriptionQ2),
    },
    {
        id: 3,
        sort: 3,
        title: intl.formatMessage(messages.titleQ3),
        answer: intl.formatMessage(messages.descriptionQ3),
    },
    {
        id: 4,
        sort: 4,
        title: intl.formatMessage(messages.titleQ4),
        answer: intl.formatMessage(messages.descriptionQ4),
    },
    {
        id: 5,
        sort: 5,
        title: intl.formatMessage(messages.titleQ5),
        answer: intl.formatMessage(messages.descriptionQ5),
    },
];
