import { useUnit } from 'effector-react';
import { AuthStateModel } from '@common/entities/auth-state';
import { SubscriptionsModel } from '@common/entities/subscriptions';
const showSkeleton = ({ skipCheckSubscription = false, offersState }) => {
    const authState = useUnit(AuthStateModel.$authState);
    const subscriptionStatusAreLoading = useUnit(SubscriptionsModel.getSubscriptionStatusFx.pending);
    const subscriptionsListAreLoading = useUnit(SubscriptionsModel.getSubscriptionsFx.pending);
    const offerIsExist = offersState === 'success' || offersState === 'error';
    const offerLoading = offersState === 'loading';
    switch (authState) {
        case 'idle':
            return true;
        case 'authorized':
            if (!skipCheckSubscription) {
                if (subscriptionStatusAreLoading || subscriptionsListAreLoading) {
                    return true;
                }
            }
            if (!offerIsExist || offerLoading) {
                return true;
            }
            return false;
        case 'unauthorized':
            if (!offerIsExist || offerLoading) {
                return true;
            }
            return offerLoading;
    }
};
export default showSkeleton;
