import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    layout_title: {
        id: 'layout_title',
        defaultMessage: 'Подписка на VK Музыку',
    },
    hero_image_alt: {
        id: 'hero_image_alt',
        defaultMessage: 'логотип',
    },
    hero_title_default: {
        id: 'hero_title_default',
        defaultMessage: 'слушайте музыку{br}и&nbsp;аудиокниги{br}офлайн и без&nbsp;рекламы',
    },
});
