import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    titleQ1: {
        id: 'titleQ1',
        defaultMessage: 'как продлевается подписка?',
    },
    descriptionQ1: {
        id: 'descriptionQ1',
        defaultMessage: 'Каждый месяц в день оплаты деньги списываются с привязанной карты автоматически.',
    },
    titleQ2: {
        id: 'titleQ2',
        defaultMessage: 'до какого числа действует подписка?',
    },
    descriptionQ2: {
        id: 'descriptionQ2',
        defaultMessage: 'Дату окончания подписки можно узнать в личном кабинете. Смотрите раздел «Моя подписка».',
    },
    titleQ3: {
        id: 'titleQ3',
        defaultMessage: 'если у меня есть промокод, то зачем вводить данные платёжной карты?',
    },
    descriptionQ3: {
        id: 'descriptionQ3',
        defaultMessage: 'Когда промокод закончит действовать, подписка продлится автоматически: с привязанной карты спишется оплата за следующий месяц. Дату списания средств можно узнать в настройках, в разделе «Моя подписка».',
    },
    titleQ4: {
        id: 'titleQ4',
        defaultMessage: 'как отменить подписку?',
    },
    descriptionQ4: {
        id: 'descriptionQ4',
        defaultMessage: 'Перейдите в личный кабинет и откройте раздел «Моя подписка». Нажмите «Управлять подпиской» и выберите «Отменить подписку».',
    },
    titleQ5: {
        id: 'titleQ5',
        defaultMessage: 'можно ли вернуть деньги за подписку?',
    },
    descriptionQ5: {
        id: 'descriptionQ5',
        defaultMessage: 'Подписка продлевается автоматически. Если вы не отменили продление подписки заранее — до списания средств, вернуть деньги не получится. Вы сможете пользоваться преимуществами подписки до окончания оплаченного периода.',
    },
    meta_description: {
        id: 'meta_description',
        defaultMessage: 'VK Музыка — это миллионы треков, тысячи подкастов и эксклюзивные альбомы артистов. Оформите подписку и слушайте музыку офлайн, без рекламы и в фоне. Первый месяц — бесплатно. Вся ваша коллекция музыки доступна ВКонтакте или в отдельном приложении VK Музыка.',
    },
    meta_keywords: {
        id: 'meta_keywords',
        defaultMessage: 'Музыка, Music, VK, VK музыка, подписка, музыка, музыка без рекламы, слушать без интернета',
    },
    meta_siteName: {
        id: 'meta_siteName',
        defaultMessage: 'VK',
    },
    meta_title: {
        id: 'meta_title',
        defaultMessage: 'Подписка на VK Музыку',
    },
});
