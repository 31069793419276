import OgImage from '../images/og_page.jpg';
import { messages } from './messages';
import { intl } from '@common/shared/lib/intl';
export const META_TAGS = [
    {
        name: 'description',
        content: intl.formatMessage(messages.meta_description),
    },
    {
        name: 'keywords',
        content: intl.formatMessage(messages.meta_keywords),
    },
    {
        property: 'og:image',
        content: OgImage.src,
    },
    {
        property: 'og:site_name',
        content: intl.formatMessage(messages.meta_siteName),
    },
    {
        property: 'og:title',
        content: intl.formatMessage(messages.meta_title),
    },
    {
        property: 'og:description',
        content: intl.formatMessage(messages.meta_description),
    },
];
